import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "../Styles/filters.css";
import Offcanvas from 'react-bootstrap/Offcanvas';


const minPrice = [0, 600, 1000, 1500, 2000, 2600, 4000, 5000];
const maxPrice = [600, 1000, 1500, 2000, 2600, 4000, "5000+"]

function Filters({
  size,
  setSize,
  color,
  setColor,
  minprice,
  setMinprice,
  maxprice,
  setMaxprice,
  sizes,
  colors,
  show,
  handleClose
}) {

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (show) {
      setIsAnimating(true);
    } else {
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [show]);

  const handleSizeChange = (e) => {
    const value = e.target.value;
    setSize((prevSizes) =>
      prevSizes.includes(value) ? prevSizes.filter((s) => s !== value) : [...prevSizes, value]
    );
  };

  const handleColorChange = (e) => {
    const value = e.target.value;
    setColor((prevColors) =>
      prevColors.includes(value) ? prevColors.filter((c) => c !== value) : [...prevColors, value]
    );
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        scroll={true}
        className={`offcanvas ${isAnimating ? (show ? 'offcanvas-enter' : 'offcanvas-exit') : ''}`}
        style={{
          backgroundColor: "white",
          boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.06)"
        }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontWeight: "bold", color: "black", fontSize: "24x" }}>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <div style={{ borderBottom: "0.5px solid #807575" }} className="mx-3"></div>
        <Offcanvas.Body style={{ padding: "0px" }}>
          <div className="filter-section px-3">
            <div className="form-group" style={{
              borderBottom: "0.5px solid #807575",
              padding: "20px 0px"
            }}>
              <h5 className="filter-title">Price</h5>
              <div className="d-flex align-items-center justify-content-between">
                <Dropdown>
                  <Dropdown.Toggle
                    className="border border-dark px-2"
                    variant=""
                    id="dropdown-basic"
                  >
                    ₹ {minprice}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {minPrice.map(option => (
                      <Dropdown.Item
                        key={option}
                        onClick={() => setMinprice(option)}
                      >
                        ₹ {option}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <span className="mx-2">To</span>
                <Dropdown>
                  <Dropdown.Toggle
                    className="border border-dark px-2"
                    variant=""
                    id="dropdown-basic"
                  >
                    ₹ {maxprice}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {maxPrice.map(option => (
                      <Dropdown.Item
                        key={option}
                        onClick={() => setMaxprice(option)}
                      >
                        ₹ {option}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>


            {
              sizes && sizes.length > 0 && <div className="form-group" style={{
                borderBottom: "0.5px solid #807575",
                padding: "20px 0px"
              }}>
                <h5 className="filter-title">Size</h5>
                <div className="filter-options">
                  {sizes.map((sizeOption) => (
                    <div key={sizeOption} className="form-check">
                      <input
                        type="checkbox"
                        value={sizeOption}
                        onChange={handleSizeChange}
                        checked={size.includes(sizeOption)}
                        className="form-check-input"
                      />
                      <label className="form-check-label">{sizeOption}</label>
                    </div>
                  ))}
                </div>
              </div>
            }
            {
              colors && colors?.length > 0 && <div className="form-group" style={{
                padding: "20px 0px"
              }}>
                <h5 className="filter-title">Color</h5>
                <div className="filter-options">
                  {colors.map((colorOption) => (
                    <div key={colorOption} className="form-check">
                      <input
                        type="checkbox"
                        value={colorOption}
                        onChange={handleColorChange}
                        checked={color.includes(colorOption)}
                        className="form-check-input"
                      />
                      <label className="form-check-label">{colorOption}</label>
                    </div>
                  ))}
                </div>
              </div>
            }
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Filters;
