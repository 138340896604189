import React, { useEffect, useState } from "react";
import OrderDetails from "./../Styles/OrderDetails.css";
import { orderIteamFetch, orderCancelApi } from "../APIS/apis";
import TrackModel from "./TrackModel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TrackOrderAPi } from "../APIS/ShippingApi"



const OrderDetail = ({
  getSingleAddress,
  getSelectedLanguage,
  orderId,
  orderNumber,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigate();


  const [orderDetail, setOrderDetail] = useState([]);
  const variations = orderDetail.variation;

  const [orderShipping, setOrderShipping] = useState([]);
  const [open, setOpen] = useState(false);
  const [ordercancel, setOrdercancel] = useState("");
  const [trackData, setTrackData] = useState();
  const [updateOrderDetailPage, setUpdateOrderDetailPage] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    let Relove_Token = localStorage.getItem("Relove_Token");

    let fromdata = {
      language: getSelectedLanguage,
      orderId: orderId,
      orderNumber: orderNumber,
    };

    orderIteamFetch(Relove_Token, fromdata, (callback) => {
      // console.log(callback, "123456789")
      setOrderDetail(callback.data.orders);
      setOrderShipping(callback.data.orders.addressShipping);
      setOrdercancel(callback?.data?.orders?.status);

    });
  }, [updateOrderDetailPage]);




  const handleTrack = () => {
    // const awdNumber = orderDetail?.awd_number;
    let Relove_Token = localStorage.getItem("Relove_Token");
    // console.log(awdNumber)
    let fromdata = {
      waybillNo: "19332510000140"
    }
    TrackOrderAPi(Relove_Token, fromdata, (callback) => {
      console.log("callback shipping", callback.data.ShipmentData[0].Shipment)
      setTrackData(callback.data.ShipmentData[0].Shipment)
      // setTrackordernumber(callback)
      setOpen(true);
    })


  };

  const orderCancel = () => {
    let Relove_Token = localStorage.getItem("Relove_Token");
    let Data = {
      language: getSelectedLanguage,
      orderId: orderId,
      orderNumber: orderNumber,
    };

    let formdata = {
      orderId: orderDetail.order_id,
      orderItemId: [orderDetail.product_id],
    };
    orderCancelApi(Relove_Token, formdata, Data, setUpdateOrderDetailPage, updateOrderDetailPage)


  };



  return (
    <div className="orderheading">
      <div className="order-adders">
        <h4 className="fw-bold">{t("DeliveryOrderADD")}</h4>
        <h6 className="fw-bold">{orderShipping.name}</h6>
        <div className="d-flex flex-wrap">
          <span className="fw-bold">
            {t("Address")}({orderShipping.address_type})
          </span>
          <span className="fw-bold ms-2 me-2">:</span>
          <span className="">
            <p className="mb-0">{orderShipping.street}</p>
            <span className="d-inline block d-block">
              {orderShipping.landmark}
            </span>
            <span className="">{orderShipping.city}</span>
            <span className="d-inline block d-block">
              {orderShipping.state}
            </span>
            <span className="">{orderShipping.code}</span>
          </span>{" "}
        </div>
        <div className="d-flex flex-wrap">
          <h6 className="fw-bold mb-0">{t("PhoneNumber")}</h6>
          <span className="fw-bold ms-2 me-2">:</span>
          <p className="mb-0">
            <span>{orderShipping.mobile}</span>
          </p>
        </div>
      </div>
      <div className="order-adders ">
        <div className="row d-flex align-items-start">
          <div className="col-sm-3 col-12">
            <div className="order-adders-image">
              <img src={orderDetail.productImage} alt="pro" />
            </div>
          </div>
          <div className="col-sm-9 col-12 mt-sm-0 mt-3">
            <p className="fw-bold mb-1"> {orderDetail.productName}</p>

            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                <p>{variations ? variations.variations[0].attributeName : "NA"}  <span className="ms-2 me-2">:</span></p>
              </p>
              <p>{variations ? variations.variations[0].variationName : "NA"}</p>
            </div>
            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                <p>{variations ? variations.variations[1].attributeName : "NA"} <span className="ms-2 me-2">:</span></p>
              </p>
              <p>{variations ? variations.variations[1].variationName : "L"}</p>
            </div>
            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                {t("Quantity")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.quantity}</p>
            </div>
            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                Sale Price
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.sale_price}</p>
            </div>

            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                {t("BasePrice")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.base_price}</p>
            </div>

            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                {t("OrderId")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.order_id}</p>
            </div>
            <div className="d-flex flex-wrap delivery-address-producet">
              <p className="fw-bold">
                {t("OrderNumber")}
                <span className="ms-2 me-1">:</span>
              </p>
              <p>{orderDetail.order_number}</p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {orderDetail.status === 3 ? (
            <>
              <div className="d-flex justify-content-center">
                <button className="btn btn-danger">{t("OrderCancel")}</button>
              </div>
            </>
          ) : (
            <>
              <div className="d-sm-flex">
                <button
                  className="btn btn-success trackbutton-cancel"
                  onClick={orderCancel}
                >
                  {t("OrderCancel")}
                </button>
                <button
                  className="btn btn-success trackbutton-cancel ms-sm-3 mt-2 mt-sm-0"
                  onClick={handleTrack}
                >
                  {t("OrderTrack")}
                </button>
              </div>
            </>
          )}
          {/* <div className="d-flex justify-content-center">
          <button className="btn btn-success trackbutton-cancel" onClick={orderCancel}>{t('OrderCancel')}</button>
          <button className="btn btn-success trackbutton-cancel ms-3" onClick={handleTrack}>{t('TrackOrder')}</button>
          </div> */}
        </div>
        <TrackModel open={open} setOpen={setOpen} trackData={trackData} />
      </div>
    </div>
  );
};

export default OrderDetail;
